/* Import Team pictures */
import { Style } from './Globals'
import NavMagicContainer from './NavMagicContainer'


const Footer = () => 
  <address className='mt-6'>
      A.N.K. Tours AG<br />
      Hanspeter Niklaus<br />
      Gerberstrasse 9<br />
      4410 Liestal<br />
      061 951 24 24<br />
      <a href="mailto:info@ank-tours.ch" className='underline hover:no-underline'>info@ank-tours.ch</a>
  </address>


const Jobs = () => {

  return (
    <div className='my-8'>
      <NavMagicContainer />
      <div className="container m-auto text-left p-4 md:w-2/3">
      <h1 className={`${Style.Title} pt-16`}>Jobs</h1>
      
      <div className='mt-8'>Momentan kein neues Personal gesucht.</div>

      <div>
        Falls Sie trotzdem finden, Sie können unser Team bereichern, freuen wir uns auf Ihre Bewerbung.
        <Footer />
      </div>
      
    </div>
    </div>

  )
}

export default Jobs